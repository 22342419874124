const pino = require('pino');
const { logflarePinoVercel } = require('pino-logflare');
const { getEnvironement } = require('./helpers');
// create pino-logflare console stream for serverless functions and send function for browser logs
const { stream, send } = logflarePinoVercel({
	apiKey: '-l37k7RBr4sc',
	sourceToken: '83d9d8ee-b914-4ee5-a79f-7ce368471bb4'
});

// create pino loggger
const logger = pino(
	{
		browser: {
			transmit: {
				send: send
			}
		},
		base: {
			env: getEnvironement(),
			revision: process.env.VERCEL_GITHUB_COMMIT_SHA
		}
	},
	stream
);

module.exports = logger;
